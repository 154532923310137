import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import {
  FileUploadControl,
  FileUploadValidators,
} from "@iplab/ngx-file-upload";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import {
  PatientDoc,
  UploadPatientDoc,
} from "src/app/core/models/patient-docs.model";
import { PatientDocsService } from "src/app/core/services/patient-docs.service";
import { PromptType, UploadDocumentType, Utils } from "src/app/core/services/utils";
import { DocumentPreviewComponent } from "../document-attachment/document-preview/document-preview.component";
import Swal from "sweetalert2";
import { NgForm } from "@angular/forms";
import { Subscription } from "rxjs";
import { LabType, OldLabInfo } from "src/app/core/models/old-labs.model";
import { cloneDeep } from "lodash";
import { OldLabsService } from "src/app/core/services/old-labs.service";
import { ApplicationCacheService } from "src/app/core/services/application-cache.service";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-upload-document",
  templateUrl: "./upload-document.component.html",
  styleUrls: ["./upload-document.component.scss"],
})
export class UploadDocumentComponent {
  @ViewChild("patientDocForm") patientDocForm: NgForm | undefined = undefined;
  @Input() documentType: UploadDocumentType;
  @Input() patientDoc: PatientDoc;
  @Input() heading: string = "Last Clinic Encounter";
  @Input() showOrderedCheckbox: boolean;
  @Input() isAiFile: boolean = false;
  @Output() onDocumentUploaded = new EventEmitter<PatientDoc>();
  @Output() onDocumentRemoved = new EventEmitter<PatientDoc>();
  dosDate: Date;
  showOldEncounterWarning: boolean;
  isRemoving: boolean;
  newDoc: UploadPatientDoc;
  isSaving: boolean;
  isAnalyzing: boolean = false;
  errorMsg: string = "";
  errorFound: boolean = false;

  recentCreatedDoc: PatientDoc;

  currentPatientName: string = "";
  currentPatientDOB: string = "";
  private subscription: Subscription;
  labTypeToDocumentTypeMapping: Map<UploadDocumentType, LabType> = new Map([
    [UploadDocumentType.Labs, LabType.FullMetabolic],
    [UploadDocumentType.UltrasoundFindings, LabType.Ultrasound],
    [UploadDocumentType.NonInvasive, LabType.ABI],
  ]);
  labInfo: OldLabInfo;
  public readonly control = new FileUploadControl(
    {
      listVisible: true,
      discardInvalid: true,
      multiple: false,
    },
    [FileUploadValidators.filesLimit(1)]
  );

  constructor(
    private readonly _toastService: ToastrService,
    private readonly _documentService: PatientDocsService,
    private readonly _applicationCacheService: ApplicationCacheService,
    private readonly _oldLabService: OldLabsService,
    private readonly _modalService: BsModalService,
    private readonly _datePipe: DatePipe
  ) {
    this.subscription = this.control.valueChanges.subscribe(
      (values: Array<File>) => this.getImage(values[0])
    );
  }

  ngOnInit() {
    if (!this.showOrderedCheckbox) {
      return;
    }

    this.labInfo =
      this.labInfoCache ||
      new OldLabInfo(
        this.selectedEncounterId,
        this.labTypeToDocumentTypeMapping.get(this.documentType)
      );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private getImage(file: File): void {
    let that = this;
    if (this.isAiFile) {
      this.isAnalyzing = true;
      if (FileReader && file) {
        const fr = new FileReader();
        fr.onload = (e) => {
          that.newDoc = new UploadPatientDoc(
            that.selectedPatientId,
            that.selectedEncounterId,
            that.selectedProcedureId,
            that.documentType
          );
          this.newDoc.name = Utils.splitCamelCase(this.documentType);
          const base64Splitted = (e.target as any).result.split("base64,");
          that.newDoc.docData.base64String = base64Splitted[1];
          that.newDoc.docData.contentType = base64Splitted[0]
            .replace(";", "")
            .replace("data:", "");

          that.uploadAndAnalyzeFile();
        };
        fr.readAsDataURL(file);
      }
    } else {
      if (FileReader && file) {
        const fr = new FileReader();
        fr.onload = (e) => {
          this.newDoc = new UploadPatientDoc(
            this.selectedPatientId,
            this.selectedEncounterId,
            this.selectedProcedureId,
            this.documentType
          );
          this.newDoc.name = Utils.splitCamelCase(this.documentType);
          const base64Splitted = (e.target as any).result.split("base64,");
          this.newDoc.docData.base64String = base64Splitted[1];
          this.newDoc.docData.contentType = base64Splitted[0]
            .replace(";", "")
            .replace("data:", "");
        };
        fr.readAsDataURL(file);
      }
    }
  }

  uploadAndAnalyzeFile() {
    if (
      !this.newDoc.docData?.base64String ||
      !this.newDoc.docData?.contentType
    ) {
      return;
    }

    const payLoad = { ...this.newDoc };

    this._documentService
      .savePatientDoc(payLoad)
      .subscribe({
        next: (newlyCreatedDoc: PatientDoc) => {
          this._applicationCacheService.addDocumentToCache(newlyCreatedDoc);
          this.recentCreatedDoc = newlyCreatedDoc;

          this._documentService
            .readFileAndFetchDetailsJson(
              newlyCreatedDoc?.fileKey,
              PromptType.ValidateFile
            )
            .subscribe({
              next: (response: any) => {
                if (!response) {
                  return;
                }
                this.isAnalyzing = false;
                this.parseDosDate(response.DOS);
                const patientInfo =
                  this._applicationCacheService.applicationCache.patient;
                this.currentPatientName = `${patientInfo.firstName} ${patientInfo.lastName}`;
                this.currentPatientDOB = patientInfo.dob;

                if (
                  response.PatientName.toLowerCase() !=
                  this.currentPatientName.toLowerCase()
                ) {
                  this.errorFound = true;
                  this.errorMsg = "Patient Mismatch";
                  return;
                }

                const formattedDate = this._datePipe.transform(
                  this.currentPatientDOB,
                  "MM/dd/yyyy"
                );
                const responseDOB = this._datePipe.transform(
                  response.DOB,
                  "MM/dd/yyyy"
                );

                if (responseDOB != formattedDate) {
                  this.errorFound = true;
                  this.errorMsg = "DOB Mismatch";
                  return;
                }
              },
              error: (error) => {
                this.isAnalyzing = false;
              },
            });
        },
        error: (err) => {
          this._toastService.error(
            err || "Unable to save Patient doc due to unknown reason.",
            "Error!"
          );
        },
      })
      .add(() => (this.isAnalyzing = true));
  }

  importFile() {
    this.onDocumentUploaded.emit(this.recentCreatedDoc);
  }

  uploadFile() {
    if (!this.patientDocForm) {
      return;
    }
    this.patientDocForm.form.markAllAsTouched();
    if (this.patientDocForm?.invalid) {
      return;
    }
    if (
      !this.newDoc.docData?.base64String ||
      !this.newDoc.docData?.contentType
    ) {
      return;
    }
    this.isSaving = true;
    const payLoad = { ...this.newDoc };

    this._documentService
      .savePatientDoc(payLoad)
      .subscribe({
        next: (newlyCreatedDoc: PatientDoc) => {
          this._applicationCacheService.addDocumentToCache(newlyCreatedDoc);
          this.onDocumentUploaded.emit(newlyCreatedDoc);
        },
        error: (err) => {
          this._toastService.error(
            err || "Unable to save Patient doc due to unknown reason.",
            "Error!"
          );
        },
      })
      .add(() => (this.isSaving = false));
  }

  parseDosDate(date: string) {
    if (!date) {
      this.dosDate = null;
      this.newDoc.dos = null;
      return;
    }
    this.dosDate = new Date(date);
    this.newDoc.dos = new Date(date).toISOString();
  }

  openDocumentViewModal() {
    const options: ModalOptions = {
      initialState: {
        signedLink: this.patientDoc.fileLink,
      },
      class: "modal-xl child-modal modal-dialog-scrollable",
      ignoreBackdropClick: true,
    };
    this._modalService.show(DocumentPreviewComponent, options);
  }

  removePatientDoc() {
    Swal.fire({
      title: "Delete Patient Document?",
      text: "Patient document will be removed, Proceed?.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#513DCA",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete",
    }).then((result) => {
      if (!result.isConfirmed) {
        return;
      }
      this.isRemoving = true;
      this._documentService.deletePatientDoc(this.patientDoc.id).subscribe({
        next: () => {
          this._applicationCacheService.removePatientDocFromCache(
            this.patientDoc.id
          );
          this.onDocumentRemoved.emit(this.patientDoc);
        },
        error: (err) => {
          this._toastService.error(
            err || "Unable to delete document due to unknown reason.",
            "Error!"
          );
        },
        complete: () => {
          this.isRemoving = false;
        },
      });
    });
  }

  onOrderPlacedChange() {
    if (!this.labInfo.isOrderPlaced) {
      this.labInfo.whenDate = "";
      this.labInfo.where = null;
    }
    if (!this.hasChanges) {
      return;
    }
    Swal.fire({
      title: "Save Lab Info?",
      text: "Do you want to save these changes?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#513DCA",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Save",
    }).then((result) => {
      if (!result.isConfirmed) {
        this.labInfo.isOrderPlaced = this.labInfoCache?.isOrderPlaced;
        this.labInfo.whenDate = this.labInfoCache?.whenDate;
        this.labInfo.where = this.labInfoCache?.where;
        return;
      }
      this.save();
    });
  }

  save() {
    this._oldLabService.saveLabResults(this.labInfo).subscribe({
      next: (response: OldLabInfo) => {
        this.labInfo = response;
        this._applicationCacheService.updateLabInfoCache(response);
      },
      error: (err) => {
        this._toastService.error(
          err || "Unable to save lab info due to unknown reason.",
          "Error!"
        );
      },
      complete: () => {
        this.isSaving = false;
      },
    });
  }

  get selectedPatientId(): string {
    return this._applicationCacheService.applicationCache.patient.id;
  }

  get selectedProcedureId(): string {
    return this._applicationCacheService.applicationCache.appointment
      .encounterProcedure.id;
  }
  get selectedEncounterId(): string {
    return this._applicationCacheService.applicationCache.appointment.id;
  }

  get hasChanges(): boolean {
    return (
      (this.labInfo.whenDate || "") !== (this.labInfoCache?.whenDate || "") ||
      (this.labInfo.where || null) !== (this.labInfoCache?.where || null)
    );
  }
  get labInfoCache() {
    return cloneDeep(
      this._applicationCacheService.applicationCache.labs.find(
        (x) =>
          x.type === this.labTypeToDocumentTypeMapping.get(this.documentType)
      )
    );
  }
}

import { Injectable } from "@angular/core";
import { DataService } from "./data.service";
import {
  Patient,
  PatientImage,
  PatientSearchResult,
  SelectedEncounterCache,
  UpsertPatient,
} from "../models/patient.model";
import { Observable, of } from "rxjs";
import { environment } from "src/environments/environment";
import { Appointment, AppointmentChecklist } from "../models/admissions.model";
import { PatientDoc } from "../models/patient-docs.model";
import { EncounterSchedule, Procedure } from "../models/procedure.model";
import {
  BroadcastEventTypes,
  ChecklistQuestionsCache,
  EncounterChecklistQuestion,
} from "../models/lookup.model";
import { EventService } from "./event.service";
import { PriorAuth } from "../models/prior-auth.model";
import { Coverage } from "../models/coverages.model";
import { ReferralChecklist } from "../models/referral-checklist.model";
import { OldLabInfo } from "../models/old-labs.model";

@Injectable({
  providedIn: "root",
})
export class PatientService {
  selectedEncounter: SelectedEncounterCache;
  private apiStatuses: Map<string, ApiStatus>;
  constructor(
    private readonly _dataService: DataService,
    private readonly _eventService: EventService
  ) {
    this.selectedEncounter = new SelectedEncounterCache();
    this.apiStatuses = new Map<string, ApiStatus>([
      [ReferralApis.Patient, ApiStatus.Pending],
      [ReferralApis.Documents, ApiStatus.Pending],
      [ReferralApis.PriorAuth, ApiStatus.Pending],
      [ReferralApis.Coverages, ApiStatus.Pending],
      [ReferralApis.ChecklistFlags, ApiStatus.Pending],
      [ReferralApis.ChecklistQuestions, ApiStatus.Pending],
      [ReferralApis.ChecklistValues, ApiStatus.Pending],
      [ReferralApis.Labs, ApiStatus.Pending],
      [ReferralApis.ChiefComplaint, ApiStatus.Pending],
    ]);
  }

  setApiStatus(status: ApiStatus, api: string) {
    this.apiStatuses.set(api, status);
  }

  setAllApisStatus(status: ApiStatus) {
    for (let key of this.apiStatuses.keys()) {
      this.setApiStatus(status, key);
    }
  }

  getCompanyPatients(): Observable<Patient[]> {
    const url = this.apiBaseUrl + "api/v1/patients";
    return this._dataService.get(url);
  }

  getPatientById(patientId: string): Observable<Patient> {
    const url = this.apiBaseUrl + `api/v1/patients/${patientId}`;
    return this._dataService.get(url);
  }

  insertPatient(patient: Partial<Patient>): Observable<Patient> {
    const url = this.apiBaseUrl + "api/v1/patients";
    return this._dataService.post(url, patient);
  }

  updatPatient(patientId: string, patient: UpsertPatient): Observable<Patient> {
    const url = this.apiBaseUrl + `api/v1/patients/${patientId}`;
    return this._dataService.patch(url, patient);
  }

  deletePatient(patientId: string): Observable<void> {
    const url = this.apiBaseUrl + `api/v1/patients/${patientId}`;
    return this._dataService.delete(url);
  }

  get apiBaseUrl(): string {
    return environment.apiBaseUrl;
  }

  getPatientImage(patientImage: PatientImage) {
    if (!patientImage) {
      return "/assets/images/custom/patient-avatar.png";
    }
    return `data:${patientImage.contentType};base64, ${patientImage.base64String}`;
  }

  searchPatientByPartialName(
    searchTerm: string
  ): Observable<PatientSearchResult[]> {
    if (!searchTerm) {
      return of(null);
    }
    const url = `${
      this.apiBaseUrl
    }api/v1/patients/search/term?term=${encodeURIComponent(searchTerm)}`;
    return this._dataService.get(url);
  }

  addDocumentToCache(doc: PatientDoc) {
    this.selectedEncounter.documents = [
      ...this.selectedEncounter.documents,
      doc,
    ];
  }

  removePatientDocFromCache(docId: string) {
    this.selectedEncounter.documents = this.selectedEncounter.documents.filter(
      (doc) => doc.id !== docId
    );
  }

  updateScheduleCache(schedule: EncounterSchedule) {
    this.selectedEncounter.schedule = schedule;
    this._eventService.broadcast(BroadcastEventTypes.ScheduleCacheUpdated);
  }

  updateProcedureCache(procedure: Procedure) {
    this.selectedEncounter.procedure = procedure;
    this._eventService.broadcast(BroadcastEventTypes.ProcedureCacheUpdated, {
      ...procedure,
    });
  }

  updateAppointmentCache(updatedAppointment: Appointment) {
    this.selectedEncounter.appointment = updatedAppointment;
    this._eventService.broadcast(
      BroadcastEventTypes.AppointmentUpdated,
      updatedAppointment
    );
  }

  updatePriorAuthCache(updatedPriorAuth: PriorAuth) {
    this.selectedEncounter.priorAuth = updatedPriorAuth;
    this._eventService.broadcast(
      BroadcastEventTypes.PriorAuthUpdated,
      updatedPriorAuth
    );
  }

  updateCoverageCache(updatedCoverages: Coverage[]) {
    this.selectedEncounter.coverages = updatedCoverages;
    this._eventService.broadcast(
      BroadcastEventTypes.CoverageUpdated,
      updatedCoverages
    );
  }

  updateChiefComplaintCache(updatedChiefComplaint: string) {
    this.selectedEncounter.chiefComplaint = updatedChiefComplaint;
    this._eventService.broadcast(
      BroadcastEventTypes.ChiefComplaintUpdated,
      updatedChiefComplaint
    );
  }

  setChecklistQuestionsCache(questions: ChecklistQuestionsCache[]) {
    this.selectedEncounter.checklistQuestions = questions;
    this._eventService.broadcast(BroadcastEventTypes.ChecklistQuestionsUpdated);
  }

  updateChecklistQuestionCache(questions: EncounterChecklistQuestion[]) {
    questions.forEach((question) => {
      const matchingQuestion = this.selectedEncounter.checklistQuestions.find(
        (q) => q.id === question.id
      );
      if (!matchingQuestion) {
        return;
      }
      matchingQuestion.answer = question.answer;
      matchingQuestion.value = question.value;
    });
    this._eventService.broadcast(BroadcastEventTypes.ChecklistQuestionsUpdated);
  }

  updateLabInfoCache(labInfo: OldLabInfo) {
    const matchingLabInfo = this.selectedEncounter.labs.find(
      (x) => x.id === labInfo.id
    );
    if (!matchingLabInfo) {
      this.selectedEncounter.labs = [...this.selectedEncounter.labs, labInfo];
    } else {
      Object.assign(matchingLabInfo, labInfo);
    }
  }

  updateAppointmentChecklistFlags(checklist: AppointmentChecklist) {
    this.selectedEncounter.checklistFlags = checklist;
  }

  updateAppointmentChecklistValues(checklist: ReferralChecklist) {
    this.selectedEncounter.checklistValues = checklist;
  }

  broadcastProgressUpdate() {
    this._eventService.broadcast(BroadcastEventTypes.RequiresProgressUpdate);
  }

  clearSelection() {
    this.selectedEncounter = new SelectedEncounterCache();
  }
}

export enum ApiStatus {
  Pending = "Pending",
  Success = "Success",
  Failed = "Failed",
}

export enum ReferralApis {
  Patient = "patient",
  Documents = "documents",
  PriorAuth = "priorAuth",
  Coverages = "coverages",
  ChecklistFlags = "checklistFlags",
  ChecklistQuestions = "checklistQuestions",
  ChecklistValues = "checklistValues",
  Labs = "labs",
  ChiefComplaint = "chiefComplaint",
  Activity = "activity",
}

import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { PatientDocsService } from 'src/app/core/services/patient-docs.service';
import { PatientService } from 'src/app/core/services/patient.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-document-preview',
  templateUrl: './document-preview.component.html',
  styleUrls: ['./document-preview.component.scss']
})
export class DocumentPreviewComponent implements OnInit {
  @Input() signedLink: string;
  isLoading: boolean;
  constructor(private readonly _docService: PatientDocsService,
    private readonly _toastService: ToastrService,
    public bsModalRef: BsModalRef) {
  }

  ngOnInit(): void {
      if (this.signedLink.startsWith('https://')) {
        return;
      }
      this.isLoading = true;
      this._docService.getDocumentLinkByFileName(this.signedLink)
        .subscribe({
          next: link => this.signedLink = link,
          error: () => {
            this.showErrorMessage();
          }
        }).add(() => this.isLoading = false);
  }

  showErrorMessage() {
    this.bsModalRef.hide();
    Swal.fire({
      title: 'Invalid Url',
      text: "Could not preview document because the link does not appear to be valid.",
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#513DCA',
      confirmButtonText: 'OK'
    });
  }
}

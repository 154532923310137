<div class="upload-document-container">
  <div class="upload-document-container__header">
    <h5 class="m-0 fw-bold">{{ heading }}</h5>
    <div *ngIf="showOrderedCheckbox" class="m-0">
      <label class="form-check-label me-2" for="ordered-input">Ordered</label>
      <input
        class="form-check-input"
        type="checkbox"
        name="ordered-input"
        id="ordered-input"
        (change)="onOrderPlacedChange()"
        [(ngModel)]="labInfo.isOrderPlaced"
      />
    </div>
  </div>
  <div class="upload-document-container__body">
    <ng-container
      *ngIf="
        showOrderedCheckbox && labInfo.isOrderPlaced;
        else documentUploadPanel
      "
    >
      <app-lab-ordered-view [labInfo]="labInfo"></app-lab-ordered-view>
    </ng-container>
    <ng-template #documentUploadPanel>
      <ng-container *ngIf="!patientDoc?.fileLink; else docDetails">
        <file-upload [control]="control" class="lab-test-upload-container">
          <ng-template
            let-isFileDragDropAvailable="isFileDragDropAvailable"
            #placeholder
          >
            <div
              *ngIf="control.size === 0"
              class="d-flex flex-column justify-content-between align-items-center"
            >
              <img
                role="button"
                src="assets/images/custom/file.png"
                height="32"
              />
              <ng-container
                *ngIf="isFileDragDropAvailable; else isNotDragDropAvailable"
              >
                <span class="drag-drop-msg"
                  >Drag and drop file to
                  <span class="drag-drop-upload-text">upload</span></span
                >
              </ng-container>
              <ng-template #isNotDragDropAvailable>
                <span class="text-center mt-1 text-nowrap"
                  >Click here to upload</span
                >
              </ng-template>
            </div>
          </ng-template>

          <ng-template
            let-i="index"
            let-file="file"
            let-control="control"
            #item
          >
            <div class="uploaded-file-info">
              <div class="me-2">
                <img
                  role="button"
                  src="assets/images/custom/pdf-doc.png"
                  height="32"
                />
              </div>
              <span class="text-start me-2 flex-grow-1">{{ file.name }}</span>
              <button
                type="button"
                class="btn tertiary-button square pink-bg"
                (click)="control.removeFile(file)"
              >
                <i class="fa-solid fa-xmark fs-5 text-danger"></i>
              </button>
            </div>
            <form #patientDocForm="ngForm">
              <div class="d-flex justify-content-between align-items-center">
                <input
                  type="date"
                  class="form-control w-10rem"
                  name="dos"
                  [ngModel]="dosDate | date : 'yyyy-MM-dd'"
                  (ngModelChange)="parseDosDate($event)"
                  [ngClass]="{
                    'is-invalid':
                      dosDateField &&
                      dosDateField.touched &&
                      dosDateField.invalid
                  }"
                  #dosDateField="ngModel"
                  required
                />
                <button
                  [disabled]="isSaving"
                  class="btn tertiary-button"
                  (click)="uploadFile()"
                >
                  <i
                    *ngIf="isSaving"
                    class="fa-duotone fa-spinner-third fa-spin fs-5 me-1"
                  ></i>
                  Upload
                </button>
              </div>
            </form>
          </ng-template>
        </file-upload>
      </ng-container>
      <ng-template #docDetails>
        <div class="document-details">
          <div class="document-details__action">
            <div class="btn-group" dropdown container="body">
              <i
                class="fa-solid fa-ellipsis-vertical dropdown-toggle ellipsis-btn"
                role="button"
                dropdownToggle
                data-toggle="dropdown"
                aria-expanded="true"
              ></i>
              <div class="dropdown-menu dropdown-menu-end" *dropdownMenu>
                <span
                  class="dropdown-item"
                  role="button"
                  (click)="openDocumentViewModal()"
                  >View</span
                >
                <span
                  class="dropdown-item"
                  role="button"
                  (click)="removePatientDoc()"
                  >Delete</span
                >
              </div>
            </div>
          </div>
          <div class="document-details__body">
            <img
              class="document-details__body--icon"
              src="assets/images/custom/pdf-doc.png"
            />
            <div class="flex-grow-1">
              <span class="document-details__info--name">{{
                patientDoc?.name
              }}</span>
              <span class="document-details__info--date">{{
                patientDoc?.dos | date : "mediumDate"
              }}</span>
            </div>
          </div>
          <p *ngIf="showOldEncounterWarning" class="encounter-warning">
            <i class="fa-solid fa-exclamation-triangle me-1"></i>
            Encounter older then 30 days
          </p>
        </div>
      </ng-template>
    </ng-template>
  </div>
</div>

export interface UserTypeLookup {
  description: string;
  id: string;
  name: string;
}

export interface EnumerationItem {
  displayName: string;
  value: string;
  parentValue?: string;
  children?: EnumerationItem[];
}

export interface SelectableEnumerationItem extends EnumerationItem {
  isSelected: boolean;
}

export interface IcdCode {
  id: string;
  text: string;
}

export interface QuestionLookup {
  id: string;
  title: string;
  questionLabel: string;
}

export enum BroadcastEventTypes {
  RiskFactorsUpdated = "riskFactorsUpdated",
  AppointmentUpdated = "appointmentUpdated",
  PatientLoaded = "patientLoaded",
  MedicationSaved = "medicationSaved",
  PatientDocsLoaded = "patientDocsLoaded",
  CoverageUpdated = "coverageUpdated",
  SaveChecklist = "saveChecklist",
  ScheduleCacheUpdated = "scheduleCacheUpdated",
  ProcedureCacheUpdated = "procedureCacheUpdated",
  OpenReferralModal = "openReferralModal",
  RequiresProgressUpdate= "requiresProgressUpdate",
}

<div class="modal-header justify-content-between">
    <div class="modal-title pull-left d-flex align-items-center">
        <h4 class="fw-normal m-0 text-uppercase"></h4>
    </div>
    <button type="button" class="btn-close close ms-1" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
</div>
<div *ngIf="!isLoading && signedLink" class="modal-body modal-fixed-height">
    <iframe [src]="signedLink | safeUrl" width="100%" height="99%" frameborder="0"></iframe>
</div>
<app-loader *ngIf="isLoading"></app-loader>
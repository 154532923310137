import { Component, Input, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { DateTime } from "luxon";
import { ToastrService } from "ngx-toastr";
import { OldLabInfo } from "src/app/core/models/old-labs.model";
import { ApplicationCacheService } from "src/app/core/services/application-cache.service";
import { OldLabsService } from "src/app/core/services/old-labs.service";
import { Utils } from "src/app/core/services/utils";

@Component({
  selector: "app-lab-ordered-view",
  templateUrl: "./lab-ordered-view.component.html",
  styleUrls: ["./lab-ordered-view.component.scss"],
})
export class LabOrderedViewComponent {
  @ViewChild("labInfoForm") labInfoForm: NgForm | undefined = undefined;
  @Input() labInfo: OldLabInfo;
  whenDate: Date;
  isReadOnly: boolean;
  isSaving: boolean;
  constructor(
    private readonly _oldLabService: OldLabsService,
    private readonly _applicationCacheService: ApplicationCacheService,
    private readonly _toastService: ToastrService
  ) {}
  ngOnInit() {
    this.isReadOnly = !!(this.labInfo.whenDate && this.labInfo.where);
    this.whenDate = this.labInfo.whenDate
      ? DateTime.fromISO(this.labInfo.whenDate).toJSDate()
      : null;
  }

  parseWhenDate(date: string) {
    if (!date) {
      this.labInfo.whenDate = null;
      return;
    }
    this.labInfo.whenDate = Utils.formatISODateWithoutTime(new Date(date));
  }

  save() {
    if (!this.labInfoForm) {
      return;
    }
    this.labInfoForm.form.markAllAsTouched();
    if (this.labInfoForm?.invalid) {
      return;
    }
    this.isSaving = true;
    this._oldLabService
      .saveLabResults(this.labInfo)
      .subscribe({
        next: (response: OldLabInfo) => {
          this.labInfo = response;
          this._applicationCacheService.updateLabInfoCache(response);
          this.isReadOnly = true;
        },
        error: (err) => {
          this._toastService.error(
            err || "Unable to save lab info due to unknown reason.",
            "Error!"
          );
        },
      })
      .add(() => (this.isSaving = false));
  }
}

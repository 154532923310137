<div class="document-generator-container">
  <div class="modal-header">
    <h4 class="modal-title pull-left fw-bold text-uppercase">{{ docType }}</h4>
    <span class="patient-name"> {{ patientName }}</span>
    <button
      type="button"
      class="btn-close close pull-right"
      aria-label="Close"
      (click)="bsModalRef.hide()"
    >
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body p-0">
    <button
      class="btn primary-button small floating-btn"
      type="button"
      (click)="useDefaultProtocol()"
    >
      Use Standing Order
    </button>
    <editor [init]="editorConfig" [(ngModel)]="docTemplate"> </editor>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      [disabled]="isSaving"
      class="btn primary-button"
      (click)="save()"
    >
      <i
        *ngIf="isSaving"
        class="fa-duotone fa-spinner-third fa-spin fs-5 me-1"
      ></i>
      Save & Close
    </button>
  </div>
</div>

export class ProcedureDocument {
  createdAt: string;
  documentContent: string;
  documentName: string;
  id: string;
  nurseKey: string;
  patientKey: string;
  patientUrl: string;
  patientSignature: Signature;
  providerSignature: Signature;
  nurseSignature: Signature;
  procedureId: string;
  providerKey: string;
  name?: string; //provider name
  signatureDate?: string; // provider signature date
  nurseName?: string;
  nurseSignatureDate?: string;
  patientName: string;
  patientSignatureDate: string;
  status: "Draft" | "Submitted" | "Signed";
  constructor(procedureId: string, docType: string) {
    this.procedureId = procedureId;
    this.documentName = docType;
    this.status = "Draft";
  }
}

export interface Signature {
  base64String: string;
  contentType: string;
}

export interface ProcedureDocType {
  id?: string;
  name: string;
  isDisabled: boolean;
  status: "Draft" | "Submitted" | "Signed";
}

export class ProviderProtocols {
  preOpProtocol: string;
  postOpProtocol: string;
  dischargeProtocol: string;
  signatureKey: string;
  name: string;
  constructor() {
    this.preOpProtocol = "";
    this.postOpProtocol = "";
    this.dischargeProtocol = "";
  }
}

import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, filter, switchMap, take } from "rxjs/operators";

import { AuthenticationService } from "../services/auth.service";
import { AuthToken } from "../models/auth.models";
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(private authenticationService: AuthenticationService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401) {
          return this.refreshToken(request, next);
        }
        let error = err?.error?.message || err?.error?.messages || err;
        if (err?.error?.maxLimitForProduct) {
          error = {
            maxLimitForProduct: err?.error?.maxLimitForProduct,
            messages: error,
          };
        }
        return throwError(error);
      })
    );
  }

  refreshToken(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const refreshToken = this.authenticationService.refreshToken;

      if (!refreshToken) {
        this.authenticationService.logout();
        return;
      }
      return this.authenticationService.getNewAccessToken(refreshToken).pipe(
        switchMap((token: AuthToken) => {
          this.isRefreshing = false;

          this.authenticationService.persistAuthData(token);
          this.refreshTokenSubject.next(token.accessToken);

          return next.handle(this.addTokenHeader(request, token.accessToken));
        }),
        catchError((err) => {
          this.isRefreshing = false;
          this.authenticationService.logout();
          return throwError(() => err);
        })
      );
    }
    return this.refreshTokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}

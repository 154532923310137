<ng-container *ngIf="patientDoc?.fileLink">
  <span
    class="text-primary fw-bold"
    role="button"
    (click)="openDocumentViewModal()"
    >{{ patientDoc.name }}</span
  >
  <i
    *ngIf="!isRemoving"
    class="fa-solid fa-xmark ms-2 text-danger"
    role="button"
    (click)="removePatientDoc()"
  ></i>
  <i
    *ngIf="isRemoving"
    class="fa-duotone fa-spinner-third fa-spin small ms-2"
  ></i>
</ng-container>
<ng-container *ngIf="!patientDoc?.fileLink">
  <span *ngIf="suggestedText" class="fw-bold me-2">{{ suggestedText }}</span>
  <i
    class="fa-solid fa-paperclip upload-btn"
    role="button"
    (click)="openAttachmentModal()"
  ></i>
</ng-container>

<div class="header-left column-gap-4">
  <div class="me-3">
    <div class="header-left__name">
      {{ patient?.firstName | titlecase }}
      {{ patient?.lastName | titlecase }}
    </div>
    <div class="m-0 d-flex">
      <span class="header-left__age">{{ patient?.age }} </span>
      <span class="header-left__gender">{{ patient?.gender | titlecase }}</span>
      <span class="d-flex gap-1 header-left__dob">
        <p>DOB:</p>
        {{
        patient?.dob | dateWithoutTimezone : "MM/dd/yyyy"
      }}</span>
    </div>
  </div>
  <div class="header-left__check-in">
    <div class="m-0">Check In:</div>
    <div class="m-0 text-dark">{{ checkInTime || "--" }}</div>
  </div>
  <div class="header-left__procedure">
    <p class="m-0">Procedure:</p>
    <div class="m-0 text-dark">{{ procedureText || "--" }}</div>
  </div>
  <div class="header-left__physician">
    <p class="m-0">Physician:</p>
    <div class="m-0 text-dark text-capitalize custom-text">
      {{ physicianName || "--" }}
    </div>
  </div>
</div>

import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { DataService } from "./data.service";
import { AddActivityNotePayload } from "../models/activity-notes.model";
import { catchError, Observable, of, tap } from "rxjs";
import { AppointmentChecklist } from "../models/admissions.model";
import { ToastrService } from "ngx-toastr";
import { ApplicationCacheService } from "./application-cache.service";
import { ApiStatus, ReferralApis } from "./utils";

@Injectable({
  providedIn: "root",
})
export class ActivityNotesService {
  constructor(
    private readonly _dataService: DataService,
    private readonly _toastService: ToastrService,
    private readonly _applicationCacheService: ApplicationCacheService
  ) {}

  getPatientActivitiesByProcedureId(
    patientId: string,
    procedureId: string
  ): Observable<any[]> {
    const url = `${this.apiBaseUrl}api/v1/activities/patient/${patientId}/procedure/${procedureId}`;
    return this._dataService.get(url);
  }

  getPatientActivitiesByPatientId(patientId: string) {
    const url = `${this.apiBaseUrl}api/v1/activities/patient/${patientId}`;
    return this._dataService.get(url);
  }

  addNewActivityNote(payload: AddActivityNotePayload) {
    const url = `${this.apiBaseUrl}api/v1/activities`;
    return this._dataService.post(url, payload);
  }

  deleteActivityNoteById(id: string) {
    const url = `${this.apiBaseUrl}api/v1/activities/${id}`;
    return this._dataService.delete(url);
  }

  getEncounterCheckList(
    appointmentId: string
  ): Observable<AppointmentChecklist> {
    const url = `${this.apiBaseUrl}api/v1/encounters/${appointmentId}/checklist`;
    return this._dataService.get(url).pipe(
      catchError((error) => {
        this._toastService.error(
          error || "Unable to get Encounter checklist due to unknown reason.",
          "Error!"
        );
        this._applicationCacheService.setApiStatus(
          ApiStatus.Failed,
          ReferralApis.ChecklistFlags
        );
        return of(error);
      }),
      tap((checklist: AppointmentChecklist) => {
        this._applicationCacheService.updateAppointmentChecklistFlags(
          checklist
        );
        this._applicationCacheService.setApiStatus(
          ApiStatus.Success,
          ReferralApis.ChecklistFlags
        );
      })
    );
  }

  private get apiBaseUrl(): string {
    return environment.apiBaseUrl;
  }
}

export class PreAnesthsiaSummary{
  hpi: HpiFindings;
  ultrasound: Findings;
  abi: Findings;
}
export class Findings {
  id?: string;
  procedureId: string;
  findings: string;

  constructor(procedureId: string) {
    this.procedureId = procedureId;
  }
}

export class HpiFindings {
  id?: string;
  procedureId: string;
  hpi: string;

  constructor(procedureId: string) {
    this.procedureId = procedureId;
  }
}

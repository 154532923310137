import { Appointment, AppointmentChecklist } from "./admissions.model";
import { NeurovascularAssessment } from "./neurovascular-assessment.model";
import { Patient } from "./patient.model";
import { ProcedureRoomListing } from "./procedure-room.model";
import {
  BloodGlucose,
  ChiefComplaint,
  DispenseSupply,
  EncounterSchedule,
  LeadEKG,
  Medication,
  ModifiedAldreteScoring,
  Procedure,
  SnacksAndMeals,
  Vitals,
} from "./procedure.model";
import { PatientDoc } from "./patient-docs.model";
import { PriorAuth } from "./prior-auth.model";
import { Coverage } from "./coverages.model";
import { ChecklistQuestionsCache, Provider } from "./lookup.model";
import { ReferralChecklist } from "./referral-checklist.model";
import { OldLabInfo } from "./old-labs.model";
import { PreProcedureAssessment } from "./pre-procedure-assessment";
import { Allergy } from "./allergy.model";
import { AdministerMedication } from "./administer-medication.model";
import { AccessSite } from "./access-site.model";
import { Act } from "./act.model";
import { ProcedureSummary } from "./procedure-summary.model";
import { ProcedureTimeout } from "./procedure-timeout.model";
import { CptCodesForProcedure, ICDCodesForProcedure } from "./cpt-codes.model";
import { Discharge } from "./discharge.model";
import { PreAnesthsiaSummary } from "./hpi-findings";
import {
  ProcedureDocument,
  ProviderProtocols,
} from "./procedure-document.model";

export class ApplicationCache {
  patient: Patient;
  appointment: Appointment;
  physician: Provider;
  documents: PatientDoc[] = [];
  schedule: EncounterSchedule;
  procedure: Procedure;
  priorAuth: PriorAuth;
  coverages: Coverage[] = [];
  checklistFlags: AppointmentChecklist;
  checklistQuestions: ChecklistQuestionsCache[] = [];
  checklistValues: ReferralChecklist;
  labs: OldLabInfo[] = [];
  chiefComplaint: ChiefComplaint;
  allergies: Allergy[] = [];
  preAnesthsiaSummary: PreAnesthsiaSummary;
  //procedure related objects
  procedureListing: ProcedureRoomListing;
  neurovascularAssessment: NeurovascularAssessment[] = [];
  snacksAndMeals: SnacksAndMeals[] = [];
  dispenseSupplies: DispenseSupply[] = [];
  vitals: Vitals[] = [];
  bloodGlucose: BloodGlucose[] = [];
  modifiedAldreteScoring: ModifiedAldreteScoring[] = [];
  leadEKG: LeadEKG[] = [];
  medicationReconciliation: Medication[] = [];
  preProcedureAssessment: PreProcedureAssessment;
  administerMeds: AdministerMedication[] = [];
  accessSites: AccessSite[] = [];
  acts: Act[] = [];
  procedureSummary: ProcedureSummary;
  procedureTimeout: ProcedureTimeout;
  cptCodes: CptCodesForProcedure[] = [];
  icdCodes: ICDCodesForProcedure[] = [];
  discharge: Discharge;
  procedureDocuments: ProcedureDocument[] = [];
  providerProtocols: ProviderProtocols;
}

import { Injectable } from "@angular/core";
import { DataService } from "./data.service";
import { ToastrService } from "ngx-toastr";
import { ApplicationCacheService } from "./application-cache.service";
import { catchError, Observable, of, tap, throwError } from "rxjs";
import {
  ProcedureDocument,
  ProviderProtocols,
} from "../models/procedure-document.model";
import { ApiStatus, ProcedureRoomApis } from "./utils";
import { cloneDeep } from "lodash";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ProcedureDocumentsService {
  constructor(
    private readonly _dataService: DataService,
    private readonly _toastService: ToastrService,
    private readonly _applicationCacheService: ApplicationCacheService
  ) {}

  getProcedureDocuments(procedureId: string): Observable<ProcedureDocument[]> {
    if (this.areProceduceDocsLoaded) {
      return of(this.procedureDocsCache);
    }

    const url = `${this.apiBaseUrl}api/v1/signatures/procedure/${procedureId}`;

    return this._dataService.get(url).pipe(
      tap((docs: ProcedureDocument[]) => {
        this._applicationCacheService.setProcedureDocumentsCache(docs);
        this._applicationCacheService.setApiStatus(
          ApiStatus.Success,
          ProcedureRoomApis.ProcedureDocuments
        );
      }),
      catchError((err) => {
        this._toastService.error(
          err?.message ||
            "Unable to get procedure documents due to an unknown reason.",
          "Error!"
        );
        this._applicationCacheService.setApiStatus(
          ApiStatus.Failed,
          ProcedureRoomApis.ProcedureDocuments
        );
        return throwError(() => err);
      })
    );
  }

  saveProcedureDocument(
    payload: Partial<ProcedureDocument>
  ): Observable<ProcedureDocument> {
    const url = this.apiBaseUrl + "api/v1/signatures";
    return this._dataService.post(url, payload).pipe(
      tap((doc: ProcedureDocument) => {
        this._applicationCacheService.updateProcedureDocumentsCache(doc);
      }),
      catchError((err) => {
        this._toastService.error(
          err?.message ||
            "Unable to save procedure document due to an unknown reason.",
          "Error!"
        );
        return throwError(() => err);
      })
    );
  }

  deleteProcedureDocument(id: string): Observable<void> {
    const url = this.apiBaseUrl + `api/v1/signature/${id}`;
    return this._dataService.delete(url);
  }

  getProviderProtocolsFromProfile(id: string) {
    if (this.areProviderProtocolsLoaded) {
      return of(this.providerProtocolCache);
    }

    const url = this.adminApiBaseUrl + `api/v1/users/${id}`;
    return this._dataService.get(url).pipe(
      tap((user: any) => {
        if (user?.userProfile) {
          const protocols: ProviderProtocols = {
            preOpProtocol: user.userProfile?.preOPDocument || "",
            postOpProtocol: user.userProfile?.postOPDocument || "",
            dischargeProtocol: user.userProfile?.dischargeDocument || "",
            signatureKey: user.userProfile?.signatureKey || "",
            name: user.firstName + " " + user.lastName,
          };
          this._applicationCacheService.setProviderProtocolsCache(protocols);
        } else {
          this._applicationCacheService.setProviderProtocolsCache(
            new ProviderProtocols()
          );
        }
        this._applicationCacheService.setApiStatus(
          ApiStatus.Success,
          ProcedureRoomApis.ProviderProtocols
        );
      }),
      catchError((err) => {
        this._toastService.error(
          err?.message ||
            "Unable to get provider protocols due to an unknown reason.",
          "Error!"
        );
        this._applicationCacheService.setApiStatus(
          ApiStatus.Failed,
          ProcedureRoomApis.ProviderProtocols
        );
        return throwError(() => err);
      })
    );
  }

  get areProceduceDocsLoaded(): boolean {
    return (
      this._applicationCacheService.getApiStatus(
        ProcedureRoomApis.AccessSite
      ) === ApiStatus.Success
    );
  }

  get areProviderProtocolsLoaded(): boolean {
    return (
      this._applicationCacheService.getApiStatus(
        ProcedureRoomApis.ProviderProtocols
      ) === ApiStatus.Success
    );
  }

  get providerProtocolCache(): ProviderProtocols {
    return cloneDeep(
      this._applicationCacheService.applicationCache.providerProtocols
    );
  }

  get procedureDocsCache(): ProcedureDocument[] {
    return cloneDeep(
      this._applicationCacheService.applicationCache.procedureDocuments
    );
  }

  get apiBaseUrl(): string {
    return environment.apiBaseUrl;
  }

  get adminApiBaseUrl(): string {
    return environment.adminApiBaseUrl;
  }
}

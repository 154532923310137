import { Injectable } from "@angular/core";
import { ApplicationContext } from "../models/application-context.model";
import { Observable, Subject, catchError, map, of, switchMap } from "rxjs";
import {
  AuthenticationService,
  CompanyProductLicense,
  User,
  UserCompany,
} from "./auth.service";
import {
  ChecklistQuestion,
  EnumerationItem,
  ProcedureRoomInfo,
  Provider,
} from "../models/lookup.model";
import { LookupService } from "./lookup.service";

@Injectable({
  providedIn: "root",
})
export class ApplicationContextService {
  private _applicationContext: ApplicationContext;
  private _contextChangedSubject: Subject<ApplicationContext> =
    new Subject<ApplicationContext>();

  constructor(
    private readonly _authService: AuthenticationService,
    private readonly _lookupService: LookupService
  ) {}

  get context(): Observable<ApplicationContext> {
    if (this._applicationContext) {
      return of(this._applicationContext);
    }
    return this.initializeApplicationContext().pipe(
      map((context) => {
        this._applicationContext = context;
        this._contextChangedSubject.next(context);
        return context;
      })
    );
  }

  get contextChanged$(): Subject<ApplicationContext> {
    return this._contextChangedSubject;
  }

  resetContext() {
    this._applicationContext = null;
  }

  private initializeApplicationContext = (): Observable<ApplicationContext> => {
    return this._authService.getLoggedInUser().pipe(
      catchError((error) => of(error)),
      switchMap((user: User) =>
        this._authService.getUserCompanies(user.id).pipe(
          catchError((error) => of(error)),
          switchMap((userCompanies: UserCompany[]) =>
            this._authService.getUserLicenses().pipe(
              catchError((error) => of(error)),
              switchMap((licenses: CompanyProductLicense[]) =>
                this._lookupService.getProviders().pipe(
                  catchError((error) => of(error)),
                  switchMap((providers: Provider[]) =>
                    this._lookupService.getChecklistQuestions().pipe(
                      catchError((error) => of(error)),
                      switchMap((checklistQuestions: ChecklistQuestion[]) =>
                        this._lookupService.getProcedureRoomsInfo().pipe(
                          map((procedureRoomInfo: ProcedureRoomInfo[]) => ({
                            currentlyLoggedInUser: user,
                            userCompanies,
                            providers,
                            checklistQuestions,
                            companyLicenses: licenses.map((l) => l.name),
                            hasNoBillingPhysiciansForCharts: licenses.some(
                              (l) =>
                                l.name === "charts" &&
                                l.activeBillingPhysicians === 0
                            ),
                            ...this.getProcedureRoomInfo(procedureRoomInfo),
                          }))
                        )
                      )
                    )
                  )
                )
              )
            )
          )
        )
      )
    );
  };

  private getProcedureRoomInfo(procedureRoomInfo: ProcedureRoomInfo[]) {
    const info =
      Array.isArray(procedureRoomInfo) && procedureRoomInfo.length > 0
        ? procedureRoomInfo.map((pr) => ({
            noOfBeds: Number(pr.noOfBeds) || 1,
            postProcedure: Number(pr.postProcedure) || 2,
            preProcedure: Number(pr.preProcedure) || 2,
            procedure: Number(pr.procedure) || 2,
            procedureRooms: Number(pr.procedureRooms) || 2,
            slots: pr.slots || [],
          }))[0]
        : new ProcedureRoomInfo();

    const beds: EnumerationItem[] = [];
    for (let i = 0; i < info.noOfBeds; i++) {
      beds.push({ displayName: `Bed ${i + 1}`, value: `Bed ${i + 1}` });
    }
    const procedureRooms: EnumerationItem[] = [];
    for (let i = 0; i < info.procedureRooms; i++) {
      procedureRooms.push({
        displayName: `Room ${i + 1}`,
        value: `Bed ${i + 1}`,
      });
    }

    return {
      beds,
      procedureRooms,
      postProcedureDuration: (Number(info.postProcedure) || 2) * 60,
      preProcedureDuration: (Number(info.preProcedure) || 2) * 60,
      procedureDuration: (Number(info.procedure) || 2) * 60,
      slots: info.slots,
    };
  }
}

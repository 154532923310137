import { Injectable } from "@angular/core";
import { DataService } from "./data.service";
import { environment } from "src/environments/environment";
import { OldLabInfo } from "../models/old-labs.model";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class OldLabsService {
  constructor(private readonly _dataService: DataService) {}

  getAppointmentLabs(appointmentId: string): Observable<OldLabInfo[]> {
    const url = this.apiBaseUrl + `api/v1/labs/encounter/${appointmentId}`;
    return this._dataService.get(url);
  }

  saveLabResults(payload: OldLabInfo) {
    const url = this.apiBaseUrl + `api/v1/labs`;
    return this._dataService.post(url, payload);
  }

  get apiBaseUrl(): string {
    return environment.apiBaseUrl;
  }
}

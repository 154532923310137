import { Injectable } from "@angular/core";
import { DataService } from "./data.service";
import {
  Patient,
  PatientImage,
  PatientSearchResult,
  UpsertPatient,
} from "../models/patient.model";
import { Observable, of } from "rxjs";
import { environment } from "src/environments/environment";
import {
  Appointment,
  AppointmentChecklist,
  Provider,
} from "../models/admissions.model";
import { PatientDoc } from "../models/patient-docs.model";
import { EncounterSchedule, Procedure } from "../models/procedure.model";
import { BroadcastEventTypes } from "../models/lookup.model";
import { EventService } from "./event.service";

@Injectable({
  providedIn: "root",
})
export class PatientService {
  selectedPatient: Patient;
  selectedAppointment: Appointment;
  selectedProvider: Provider;
  selectedPatientDocuments: PatientDoc[];
  selectedAppointmentSchedule: EncounterSchedule;
  selectedAppointmentProcedure: Procedure;
  selectedAppointmentChecklist: AppointmentChecklist;

  constructor(
    private readonly _dataService: DataService,
    private readonly _eventService: EventService
  ) {}

  getCompanyPatients(): Observable<Patient[]> {
    const url = this.apiBaseUrl + "api/v1/patients";
    return this._dataService.get(url);
  }

  getPatientById(patientId: string): Observable<Patient> {
    const url = this.apiBaseUrl + `api/v1/patients/${patientId}`;
    return this._dataService.get(url);
  }

  insertPatient(patient: Partial<Patient>): Observable<Patient> {
    const url = this.apiBaseUrl + "api/v1/patients";
    return this._dataService.post(url, patient);
  }

  updatPatient(patientId: string, patient: UpsertPatient): Observable<Patient> {
    const url = this.apiBaseUrl + `api/v1/patients/${patientId}`;
    return this._dataService.patch(url, patient);
  }

  deletePatient(patientId: string): Observable<void> {
    const url = this.apiBaseUrl + `api/v1/patients/${patientId}`;
    return this._dataService.delete(url);
  }

  get apiBaseUrl(): string {
    return environment.apiBaseUrl;
  }

  getPatientImage(patientImage: PatientImage) {
    if (!patientImage) {
      return "/assets/images/custom/patient-avatar.png";
    }
    return `data:${patientImage.contentType};base64, ${patientImage.base64String}`;
  }

  searchPatientByPartialName(
    searchTerm: string
  ): Observable<PatientSearchResult[]> {
    if (!searchTerm) {
      return of(null);
    }
    const url = `${
      this.apiBaseUrl
    }api/v1/patients/search/term?term=${encodeURIComponent(searchTerm)}`;
    return this._dataService.get(url);
  }

  addDocumentToCache(doc: PatientDoc) {
    this.selectedPatientDocuments = [...this.selectedPatientDocuments, doc];
  }

  removePatientDocFromCache(docId: string) {
    this.selectedPatientDocuments = this.selectedPatientDocuments.filter(
      (doc) => doc.id !== docId
    );
  }

  updateScheduleCache(schedule: EncounterSchedule) {
    this.selectedAppointmentSchedule = schedule;
    this._eventService.broadcast(BroadcastEventTypes.ScheduleCacheUpdated);
  }

  updateProcedureCache(procedure: Procedure) {
    this.selectedAppointmentProcedure = procedure;
    this._eventService.broadcast(BroadcastEventTypes.ProcedureCacheUpdated, {
      ...procedure,
    });
  }

  updateAppointmentCache(updatedAppointment: Appointment) {
    this.selectedAppointment = updatedAppointment;
    this._eventService.broadcast(
      BroadcastEventTypes.AppointmentUpdated,
      updatedAppointment
    );
  }

  updateAppointmentChecklist(checklist: AppointmentChecklist) {
    this.selectedAppointmentChecklist = checklist;
  }

  clearSelection() {
    this.selectedAppointment = null;
    this.selectedPatient = null;
    this.selectedProvider = null;
    this.selectedAppointmentSchedule = null;
    this.selectedAppointmentProcedure = null;
    this.selectedAppointmentChecklist = null;
  }
}

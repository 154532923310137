import { Component } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
@Component({
  selector: "app-view-pdf-document",
  templateUrl: "./view-pdf-document.component.html",
  styleUrls: ["./view-pdf-document.component.scss"],
})
export class ViewPdfDocumentComponent {
  htmlContent: string; //passed a param;
  displayName: string; //passed a param;
  pdfSrc: string;
  constructor(public bsModalRef: BsModalRef) {}

  ngOnInit(): void {
    this.generatePDF();
  }

  async generatePDF() {
    const div = document.createElement("div");
    div.innerHTML = this.htmlContent;
    div.style.width = "800px";
    div.style.padding = "20px 30px";
    div.style.backgroundColor = "#fff";
    div.style.position = "absolute";
    div.style.top = "-9999px";
    document.body.appendChild(div);

    const canvas = await html2canvas(div, { scale: 2 });
    const imgData = canvas.toDataURL("image/png");

    const pdf = new jsPDF("p", "mm", "a4");
    pdf.setProperties({
      title: this.displayName,
    });
    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);

    const pdfBlob = pdf.output("blob");
    const blobUrl = URL.createObjectURL(pdfBlob);
    this.pdfSrc = blobUrl; // Set URL for iframe
    console.log(div);
    document.body.removeChild(div);
  }
}

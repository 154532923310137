import { DocStatus } from "../services/utils";

export class PatientDoc {
  associatedAuthorizationId: string;
  documentType: string;
  documentTypeId: string;
  extension: string;
  fileKey: string;
  fileLink: string;
  fileName: string;
  id: string;
  isProcedureDocument: boolean;
  name: string;
  patientId: string;
  procedureId: string;
  status: DocStatus;
  updatedBy: string;
  createdAt: string;
}

export class UploadPatientDoc {
  name: string;
  documentDate: string;
  patientId: string;
  procedureId: string;
  documentType: string;
  docData: {
    base64String: string;
    contentType: string;
  };
  constructor(patientId: string, procedureId: string, documentType: string) {
    this.patientId = patientId;
    this.procedureId = procedureId;
    this.documentType = documentType || null;
    this.name = "";
    this.docData = {
      base64String: "",
      contentType: "",
    };
  }
}

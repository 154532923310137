import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

interface Event {
    type: string;
    payload?: any;
}

@Injectable({
    providedIn: 'root'
})
export class EventService {
    private handler = new Subject<Event>();

    /**
     * Broadcast the event
     * @param type type of event
     * @param payload payload
     */
    broadcast(type: string, payload: any = {}): void {
        this.handler.next({ type, payload });
    }

    /**
     * Observable for specific event types, allowing consumers to apply pipes and operators
     * @param type type of event
     * @returns Observable stream filtered by event type
     */
    on(type: string): Observable<any> {
        return this.handler.pipe(
            filter(event => event.type === type),
            map(event => event.payload)
        );
    }
}
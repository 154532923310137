import { Injectable } from "@angular/core";
import { DataService } from "./data.service";
import { environment } from "src/environments/environment";
import { BehaviorSubject, Observable, finalize, map } from "rxjs";
import { PatientDoc, UploadPatientDoc } from "../models/patient-docs.model";
@Injectable({
  providedIn: "root",
})
export class PatientDocsService {
  constructor(private readonly _dataService: DataService) {}

  private deleteSubject$: BehaviorSubject<string> = new BehaviorSubject<string>(
    null
  );

  idToBeDeleted$: Observable<string> = this.deleteSubject$.asObservable();

  savePatientDoc(payload: UploadPatientDoc): Observable<PatientDoc> {
    // this is an extra endpoint because api/v1/documents does not return the inserted record
    //if case we need to return the inserted record we can use this endpoint
    //if you want all patient records returned, use the api/v1/documents api
    const url = this.apiBaseUrl + `api/v1/documents/add`;
    return this._dataService.post(url, payload);
  }

  getPatientDocs(patientId: string): Observable<PatientDoc[]> {
    const url = this.apiBaseUrl + `api/v1/documents/patient/${patientId}`;
    return this._dataService.get(url).pipe(
      map((docs) =>
        docs.map((doc) => ({
          ...doc,
          isProcedureDocument: false, // phi currently does not supports procedural docs.
        }))
      )
    );
  }

  deletePatientDoc(documentId: string): Observable<void> {
    this.deleteSubject$.next(documentId);
    const url = this.apiBaseUrl + `api/v1/documents/${documentId}`;
    return this._dataService
      .delete(url)
      .pipe(finalize(() => this.deleteSubject$.next(null)));
  }

  getDocumentLinkByFileName(fileName: string): Observable<string> {
    const url = this.apiBaseUrl + `api/v1/documents/view?key=${fileName}`;
    return this._dataService.get(url, { responseType: "text" });
  }

  get apiBaseUrl(): string {
    return environment.apiBaseUrl;
  }
}

import { Component } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { Subject, takeUntil } from "rxjs";
import { ActivityNote } from "src/app/core/models/activity-notes.model";
import { BroadcastEventTypes } from "src/app/core/models/lookup.model";
import { ActivityNotesService } from "src/app/core/services/activity-notes.service";
import { ApplicationCacheService } from "src/app/core/services/application-cache.service";
import { ApplicationContextService } from "src/app/core/services/application-context.service";
import { EventService } from "src/app/core/services/event.service";

@Component({
  selector: "app-add-activity-modal",
  templateUrl: "./add-activity-modal.component.html",
  styleUrls: ["./add-activity-modal.component.scss"],
})
export class AddActivityModalComponent {
  isSaving: boolean;
  note: string;
  loggedInUserName: string;
  private readonly destroy$ = new Subject<void>();
  constructor(
    public bsModalRef: BsModalRef,
    private readonly _appContextService: ApplicationContextService,
    private readonly _applicationCacheService: ApplicationCacheService,
    private readonly _activityNoteService: ActivityNotesService,
    private readonly _toastService: ToastrService,
    private readonly _eventService: EventService
  ) {
    this._appContextService.context
      .pipe(takeUntil(this.destroy$))
      .subscribe((context) => {
        this.loggedInUserName =
          context.currentlyLoggedInUser.firstName +
          " " +
          context.currentlyLoggedInUser.lastName;
      });
  }

  saveActivityNote() {
    this.isSaving = true;
    this._activityNoteService
      .addNewActivityNote({
        patientId: this.patientId,
        procedureId: this.procedureId,
        notes: this.note,
        activityBy: this.loggedInUserName,
        activityType: "Action",
      })
      .subscribe({
        next: (activityNote: ActivityNote) => {
          this._eventService.broadcast(
            BroadcastEventTypes.ActivityNoteAdded,
            activityNote
          );
          this.bsModalRef.hide();
        },
        error: (err) => {
          this._toastService.error(
            err || "Unable to save activity note due to unknown reason.",
            "Error!"
          );
        },
        complete: () => (this.isSaving = false),
      });
  }

  get patientId() {
    return this._applicationCacheService.applicationCache.patient.id;
  }

  get procedureId() {
    return this._applicationCacheService.applicationCache.procedureListing.id;
  }
}

import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    // {
    //     id: 1,
    //     label: 'Dashboard',
    //     iconUrl: 'assets/images/custom/sidebar/dashboard.svg',
    //     link: '/',
    // },
    {
        id: 2,
        label: 'Admission',
        iconUrl: 'assets/images/custom/sidebar/admissions.svg',
        activeIconUrl: 'assets/images/custom/sidebar/admissions-active.svg',
        link: '/referrals',
        width: 16
    },
    {
        id: 3,
        label: 'Procedures',
        iconUrl: 'assets/images/custom/sidebar/procedures.svg',
        activeIconUrl: 'assets/images/custom/sidebar/procedures_active.svg',
        link: '/procedurs',
        width: 20
    }
];


<div class="document-container">
  <div *ngIf="patientDoc?.fileLink" class="document-container__title">
    <label class="m-0">{{ suggestedText }}</label>
    <div class="btn-group" dropdown container="body">
      <i
        class="fa-solid fa-ellipsis-vertical dropdown-toggle ellipsis-btn"
        role="button"
        dropdownToggle
        data-toggle="dropdown"
        aria-expanded="true"
      ></i>
      <div class="dropdown-menu dropdown-menu-end" *dropdownMenu>
        <span
          class="dropdown-item"
          role="button"
          (click)="openDocumentViewModal()"
          >View</span
        >
        <span class="dropdown-item" role="button" (click)="removePatientDoc()"
          >Delete</span
        >
      </div>
    </div>
  </div>
  <div class="document-container__body">
    <ng-container *ngIf="!patientDoc?.fileLink; else docInfo">
      <div class="document-container__add-new">
        <i
          class="fa-solid fa-plus fs-3"
          role="button"
          (click)="openAttachmentModal()"
        ></i>
        <label class="m-0 d-block">Attach {{ suggestedText }}</label>
      </div>
    </ng-container>
    <ng-template #docInfo>
      <img
        class="document-container__body--icon"
        src="assets/images/custom/pdf-doc.png"
      />
      <div class="flex-grow-1">
        <span class="document-container__info--name">{{
          patientDoc?.name
        }}</span>
        <span class="document-container__info--date">{{
          patientDoc?.createdAt | date : "mediumDate"
        }}</span>
      </div>
    </ng-template>
  </div>
</div>

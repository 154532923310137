import { ProcedureSlot } from "./application-context.model";

export interface UserTypeLookup {
  description: string;
  id: string;
  name: string;
}

export interface EnumerationItem {
  displayName: string;
  value: string;
  parentValue?: string;
  children?: EnumerationItem[];
}

export interface SelectableEnumerationItem extends EnumerationItem {
  isSelected: boolean;
}

export interface IcdCode {
  id: string;
  text: string;
}

export interface QuestionLookup {
  id: string;
  title: string;
  questionLabel: string;
}

export interface ChecklistQuestion {
  area: string;
  id: string;
  index: number;
  label: string;
  title: string;
  answer: boolean | null;
  value?: string;
}

export interface EncounterChecklistQuestion {
  id: string;
  questionId: string;
  answer: boolean | null;
  value?: string;
}

export interface ChecklistQuestionsCache extends EncounterChecklistQuestion {
  area: string;
  index: number;
  label: string;
  title: string;
}

export interface Provider {
  id: string;
  firstName: string;
  lastName: string;
  specialty: string;
  address: string;
  city: string;
  country: string;
  postalCode: string;
  state: string;
  npi: string;
}

export class ProcedureRoomInfo {
  noOfBeds: number;
  postProcedure: number;
  preProcedure: number;
  procedure: number;
  procedureRooms: number;
  slots: ProcedureSlot[];
  constructor() {
    this.noOfBeds = 1;
    this.postProcedure = 2;
    this.preProcedure = 2;
    this.procedure = 2;
    this.procedureRooms = 1;
    this.slots = [];
  }
}

export enum BroadcastEventTypes {
  RiskFactorsUpdated = "riskFactorsUpdated", // delete candidate
  ChiefComplaintUpdated = "chiefComplaintUpdated",
  AppointmentUpdated = "appointmentUpdated",
  PatientInfoUpdated = "patientInfoUpdated",
  MedicationSaved = "medicationSaved",
  PatientDocsLoaded = "patientDocsLoaded",
  CoverageUpdated = "coverageUpdated",
  ScheduleCacheUpdated = "scheduleCacheUpdated",
  ProcedureCacheUpdated = "procedureCacheUpdated",
  OpenReferralModal = "openReferralModal",
  RequiresProgressUpdate = "requiresProgressUpdate",
  ChecklistQuestionsUpdated = "checklistQuestionsUpdated",
  PriorAuthUpdated = "priorAuthUpdated",
  LabsInfoUpdated = "labsInfoUpdated",
  AllergiesUpdated = "allergiesUpdated",
  MedicalProblemsFromDoc = "MedicalProblemsFromDoc",
  MetabolicDataExtractedFromDoc = "metabolicDataExtractedFromDoc",
  PreAnesthsiaSummaryUpdated = "preAnesthsiaSummaryUpdated",
  //proceudre room event
  NeurovascularAssessmentUpdated = "neurovascularAssessmentUpdated",
  ProcedureRoomListingUpdated = "procedureRoomListingUpdated",
  SnacksAndMealsUpdated = "snacksAndMealsUpdated",
  DispenseSuppliesUpdated = "dispenseSuppliesUpdated",
  VitalsUpdated = "vitalsUpdated",
  BloodGlucoseUpdated = "bloodGlucoseUpdated",
  ModifiedAldreteScoringUpdated = "modifiedAldreteScoringUpdated",
  LeadEKGUpdated = "leadEKGUpdated",
  MedicationReconciliationUpdated = "medicationReconciliationUpdated",
  PreProcedureAssessmentUpdated = "preProcedureAssessmentUpdated",
  AdministerMedsUpdated = "administerMedsUpdated",
  AccessSiteUpdated = "accessSiteUpdated",
  ActsUpdated = "actsUpdated",
  ProcedureSummaryUpdated = "procedureSummaryUpdated",
  ProcedureTimeoutUpdated = "procedureTimeoutUpdated",
  CptCodesUpdated = "cptCodesUpdated",
  IcdCodesUpdated = "icdCodesUpdated",
  ActivityNoteAdded = "activityNoteAdded",
  DischargeUpdated = "dischargeUpdated",
}

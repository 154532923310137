import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ChecklistQuestionsCache } from "src/app/core/models/lookup.model";

@Component({
  selector: "app-checklist-questions",
  templateUrl: "./checklist-questions.component.html",
  styleUrls: ["./checklist-questions.component.scss"],
})
export class ChecklistQuestionsComponent {
  @Input() questions: ChecklistQuestionsCache[] = [];
  @Input() showDescription: boolean = false;
  @Input() showConfirmationButton: boolean = false;
  @Input() showNotAvailableOption: boolean = false;
  @Output() onSelectionChanged: EventEmitter<ChecklistQuestionsCache> =
    new EventEmitter<ChecklistQuestionsCache>();

  constructor() {}

  selectionChanged(question: ChecklistQuestionsCache, isSelected: boolean) {
    question.answer = isSelected;
    if (!isSelected) {
      question.value = null;
    }
    this.onSelectionChanged.emit(question);
  }

  confirmUnconfirm(question: ChecklistQuestionsCache) {
    question.answer = !question.answer;
    this.onSelectionChanged.emit(question);
  }
}

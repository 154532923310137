import { Component, Input } from "@angular/core";
import { Subject, takeUntil } from "rxjs";
import { BroadcastEventTypes } from "src/app/core/models/lookup.model";
import { Patient } from "src/app/core/models/patient.model";
import { EventService } from "src/app/core/services/event.service";
import { PatientService } from "src/app/core/services/patient.service";

@Component({
  selector: "app-patient-info-header",
  templateUrl: "./patient-info-header.component.html",
  styleUrls: ["./patient-info-header.component.scss"],
})
export class PatientInfoHeaderComponent {
  @Input() showPhoneNo: boolean = false;
  @Input() showStatus: boolean = false;

  patient: Patient;
  physicianName: string;
  appointmentStatus: string;
  checkInTime: string;
  procedureText: string;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly _patientService: PatientService,
    private readonly _eventService: EventService
  ) {
    this._eventService
      .on(BroadcastEventTypes.ProcedureCacheUpdated)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.updateProcedureText();
      });
    this._eventService
      .on(BroadcastEventTypes.ScheduleCacheUpdated)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.updateCheckInTime();
      });
  }

  ngOnInit() {
    this.patient = this._patientService.selectedPatient;
    this.physicianName = this._patientService.selectedAppointment.providerName;
    this.appointmentStatus =
      this._patientService.selectedAppointment.encounterStatus;
    this.updateCheckInTime();
    this.updateProcedureText();
  }

  updateCheckInTime() {
    const { checkIn, scheduleDate } =
      this._patientService.selectedAppointmentSchedule || {};
    if (!checkIn && !scheduleDate) {
      this.checkInTime = "";
      return;
    }
    this.checkInTime = `${checkIn || ""} ${scheduleDate || ""}`;
  }

  updateProcedureText() {
    const { region, area } =
      this._patientService.selectedAppointmentProcedure || {};
    if (!region && !area) {
      this.procedureText = "";
      return;
    }
    this.procedureText = `${region} ${area}`;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

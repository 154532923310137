import { Injectable } from "@angular/core";
import { DataService } from "./data.service";
import { environment } from "src/environments/environment";
import { Observable, catchError, map, of } from "rxjs";
import {
  ChecklistQuestion,
  EncounterChecklistQuestion,
  EnumerationItem,
  IcdCode,
  ProcedureRoomInfo,
  Provider,
  QuestionLookup,
} from "../models/lookup.model";

@Injectable({
  providedIn: "root",
})
export class LookupService {
  constructor(private readonly _dataService: DataService) {}

  getIcdCodes(searchTerm: string): Observable<IcdCode[]> {
    if (!searchTerm) {
      return of([]);
    }
    const url = `${
      this.apiBaseUrl
    }api/v1/lookup/icd-codes?term=${encodeURIComponent(searchTerm)}`;
    return this._dataService.get(url);
  }

  getMedications(searchTerm: string): Observable<any[]> {
    if (!searchTerm) {
      return of([]);
    }
    const url = `${
      this.apiBaseUrl
    }api/v1/lookup/medications/${encodeURIComponent(searchTerm)}`;
    return this._dataService.get(url);
  }

  getMedicationStrength(id: string) {
    const url = `${
      this.apiBaseUrl
    }api/v1/lookup/medications/strength/${encodeURIComponent(id)}`;
    return this._dataService.get(url);
  }

  getMedicationForms(id: string) {
    const url = `${
      this.apiBaseUrl
    }api/v1/lookup/medications/forms/${encodeURIComponent(id)}`;
    return this._dataService.get(url);
  }

  getMedicationRoutes(id: string) {
    const url = `${
      this.apiBaseUrl
    }api/v1/lookup/medications/routes/${encodeURIComponent(id)}`;
    return this._dataService.get(url);
  }

  getAllergies(searchTerm: string): Observable<EnumerationItem[]> {
    if (!searchTerm) {
      return of([]);
    }
    const url = `${
      this.apiBaseUrl
    }api/v1/lookup/allergies?term=${encodeURIComponent(searchTerm)}`;
    return this._dataService.get(url).pipe(
      map((allergies) => {
        return allergies.map((a) => ({
          // TODO: this is the id an title what will be returned from drugbank api
          //displayName: a.title,
          //value: a.drugbank_id
          // for the time being we are going to use local data so using id and name here.
          displayName: a.name,
          value: a.id,
        }));
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  getImplants(): Observable<EnumerationItem[]> {
    const url = `${this.apiBaseUrl}api/v1/implants`;
    return this._dataService.get(url).pipe(
      map((allergies) => {
        return allergies.map((i) => ({
          displayName: i.name,
          value: i.id,
        }));
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  getVaccineList(): Observable<EnumerationItem[]> {
    const url = `${this.apiBaseUrl}api/v1/immunizations`;
    return this._dataService.get(url).pipe(
      map((immunizations) => {
        return immunizations.map((i) => ({
          displayName: i.name,
          value: i.id,
        }));
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  getPayers(searchTerm: string): Observable<{ name: string }[]> {
    if (!searchTerm) {
      return of([]);
    }
    const url = `${this.apiBaseUrl}api/v1/lookup/payer-list?term=${searchTerm}`;
    return this._dataService.get(url);
  }

  getQuestionsByType(type: string): Observable<QuestionLookup[]> {
    const url = `${this.apiBaseUrl}api/v1/lookup/questions-list/${type}`;
    return this._dataService.get(url);
  }

  getProviders(): Observable<Provider[]> {
    const url = `${this.accountsApiBaseUrl}api/v1/users/providers`;
    return this._dataService.get(url);
  }

  getProcedureRoomsInfo(): Observable<ProcedureRoomInfo[]> {
    const url = `${this.accountsApiBaseUrl}api/v1/locations/company`;
    return this._dataService.get(url);
  }

  getChecklistQuestions(): Observable<ChecklistQuestion[]> {
    const url = `${this.apiBaseUrl}api/v1/question`;
    return this._dataService.get(url);
  }

  getEncounterChecklistQuestions(
    encounterId: string
  ): Observable<EncounterChecklistQuestion[]> {
    const url = `${this.apiBaseUrl}api/v1/question/encounter/${encounterId}`;
    return this._dataService.get(url);
  }

  saveChecklistQuestions(
    patientId: string,
    encounterId: string,
    procedureId: string,
    questions: EncounterChecklistQuestion[]
  ): Observable<EncounterChecklistQuestion[]> {
    const url = `${this.apiBaseUrl}api/v1/question`;
    return this._dataService.post(url, {
      patientId,
      procedureId,
      encounterId,
      questions,
    });
  }

  get accountsApiBaseUrl(): string {
    return environment.adminApiBaseUrl;
  }

  private get apiBaseUrl(): string {
    return environment.apiBaseUrl;
  }
}

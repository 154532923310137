<div class="modal-header justify-content-between">
  <div class="modal-title pull-left d-flex">
    <h4 class="fw-normal m-0">Add Action</h4>
  </div>
  <button
    type="button"
    class="btn-close close ms-1"
    aria-label="Close"
    (click)="bsModalRef.hide()"
  >
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>
<div class="modal-body">
  <textarea
    autosize
    class="form-control pe-5"
    name="notes-input"
    rows="5"
    [(ngModel)]="note"
  ></textarea>
  <button
    [disabled]="isSaving || !note"
    type="button"
    class="btn primary-button floating-button"
    (click)="saveActivityNote()"
  >
    <i
      [ngClass]="{
        'fa-duotone fa-spinner-third fa-spin fs-5 me-1': isSaving,
        'fa fa-check': !isSaving
      }"
    ></i>
  </button>
</div>

import { Component } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";
import {
  ProcedureDocument,
  ProviderProtocols,
} from "src/app/core/models/procedure-document.model";
import { ProcedureRoomListing } from "src/app/core/models/procedure-room.model";
import { ApplicationCacheService } from "src/app/core/services/application-cache.service";
import { ProcedureDocumentsService } from "src/app/core/services/procedure-documents.service";
import { ProcedureDocumentTypes, Utils } from "src/app/core/services/utils";

@Component({
  selector: "app-document-generator",
  templateUrl: "./document-generator.component.html",
  styleUrls: ["./document-generator.component.scss"],
})
export class DocumentGeneratorComponent {
  docId: string; // passed from the parent component
  docType: ProcedureDocumentTypes; // passed from the parent component
  isSaving: boolean;
  editorConfig: any;
  procedureDoc: ProcedureDocument;
  docTemplate: string;
  close: Subject<void>;
  patientName: string;
  constructor(
    public bsModalRef: BsModalRef,
    private readonly _procedureDocumentsService: ProcedureDocumentsService,
    private readonly _applicationCacheService: ApplicationCacheService
  ) {
    this.editorConfig = Utils.getTinyMCEConfig(700, "sliding");
    this.close = new Subject<void>();
  }

  ngOnInit() {
    this.patientName = this.procedure.patientName;
    if (!this.docId) {
      const providerProtocols: ProviderProtocols =
        this._applicationCacheService.applicationCache.providerProtocols;
      this.procedureDoc = new ProcedureDocument(
        this.procedure.id,
        this.docType
      );
      this.docTemplate = [
        ProcedureDocumentTypes.PreOpProtocol,
        ProcedureDocumentTypes.PostOpProtocol,
        ProcedureDocumentTypes.DischargeOrder,
      ].includes(this.docType)
        ? this.getProviderProtocols(providerProtocols)
        : "";
      return;
    }
    this.procedureDoc =
      this._applicationCacheService.applicationCache.procedureDocuments?.find(
        (doc) => doc.id === this.docId
      );
    this.docTemplate = this.procedureDoc?.documentContent || "";
  }

  private getProviderProtocols(providerProtocols: ProviderProtocols): string {
    switch (this.docType) {
      case ProcedureDocumentTypes.PreOpProtocol:
        return providerProtocols.preOpProtocol;
      case ProcedureDocumentTypes.PostOpProtocol:
        return providerProtocols.postOpProtocol;
      case ProcedureDocumentTypes.DischargeOrder:
        return providerProtocols.dischargeProtocol;
      default:
        return "";
    }
  }

  save() {
    this.isSaving = true;
    const payload: ProcedureDocument = {
      ...this.procedureDoc,
      documentContent: this.docTemplate,
    };
    this._procedureDocumentsService
      .saveProcedureDocument(payload)
      .subscribe(() => {
        this.bsModalRef.hide();
        this.close.next();
      })
      .add(() => (this.isSaving = false));
  }

  useDefaultProtocol() {
    const providerProtocols: ProviderProtocols =
      this._applicationCacheService.applicationCache.providerProtocols;
    this.docTemplate = this.getProviderProtocols(providerProtocols);
  }

  get procedure(): ProcedureRoomListing {
    return this._applicationCacheService.applicationCache.procedureListing;
  }
}

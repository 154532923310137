import {
  Ethnicity,
  Gender,
  MaritalStatus,
  PatientStatus,
  Relationship,
} from "../services/utils";
import { Appointment, AppointmentChecklist } from "./admissions.model";
import { Coverage } from "./coverages.model";
import { ChecklistQuestionsCache, Provider } from "./lookup.model";
import { OldLabInfo } from "./old-labs.model";
import { PatientDoc } from "./patient-docs.model";
import { PriorAuth } from "./prior-auth.model";
import { EncounterSchedule, Procedure } from "./procedure.model";
import { ReferralChecklist } from "./referral-checklist.model";

export class Patient {
  id: string;
  firstName: string;
  middleName: string;
  lastName: string;
  dob: string;
  gender: Gender | string = "";
  address: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  email: string;
  physicianName: string;
  lastAppt: string;
  addressLine2: string;
  apartmentNumber: string;
  upcomingAppt: string;
  status: PatientStatus = PatientStatus.Admitted;
  emergencyContactName: string;
  emergencyContactNumber: string;
  emergencyContactRelation: Relationship | string = "";
  maritalStatus: MaritalStatus | string = "";
  ethnicity: Ethnicity | string = "";
  insuranceInformation: string;
  preferredPharmacy: string;
  referringPhysician: string;
  mrn: string;
  age: number;
  dOS: string;
  socialSecurityNumber: string;
  consentAndAuthorization: string;
  nextOfKin: string;
  imageData: PatientImage;
  constructor() {}
}

export class UpsertPatient {
  id: string;
  firstName: string;
  middleName: string;
  lastName: string;
  dob: string;
  gender: Gender | string = "";
  address: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  email: string;
  emergencyContactName: string;
  emergencyContactNumber: string;
  emergencyContactRelation: Relationship | string = "";
  maritalStatus: MaritalStatus | string = "";
  ethnicity: Ethnicity | string = "";
  socialSecurityNumber: string;
  imageData: PatientImage;
  constructor(patientInfo: Patient) {
    this.id = patientInfo.id;
    this.firstName = patientInfo.firstName;
    this.middleName = patientInfo.middleName;
    this.lastName = patientInfo.lastName;
    this.dob = patientInfo.dob;
    this.gender = patientInfo.gender;
    this.address = patientInfo.address;
    this.addressLine2 = patientInfo.addressLine2;
    this.city = patientInfo.city;
    this.state = patientInfo.state;
    this.zip = patientInfo.zip;
    this.phone = patientInfo.phone;
    this.email = patientInfo.email;
    this.emergencyContactName = patientInfo.emergencyContactName;
    this.emergencyContactNumber = patientInfo.emergencyContactNumber;
    this.emergencyContactRelation = patientInfo.emergencyContactRelation;
    this.maritalStatus = patientInfo.maritalStatus;
    this.ethnicity = patientInfo.ethnicity;
    this.socialSecurityNumber = patientInfo.socialSecurityNumber;
    this.imageData = patientInfo.imageData;
  }
}

export interface PatientSearchResult {
  id: string;
  name: string;
  dob: string;
}

export interface PatientImage {
  base64String: string;
  contentType: string;
}

export class SelectedEncounterCache {
  patient: Patient;
  appointment: Appointment;
  physician: Provider;
  documents: PatientDoc[];
  schedule: EncounterSchedule;
  procedure: Procedure;
  priorAuth: PriorAuth;
  coverages: Coverage[];
  checklistFlags: AppointmentChecklist;
  checklistQuestions: ChecklistQuestionsCache[];
  checklistValues: ReferralChecklist;
  labs: OldLabInfo[];
  chiefComplaint: string;
}

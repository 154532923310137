<div *ngIf="labInfo.isOrderPlaced" class="px-3">
  <ng-container *ngIf="isReadOnly; else labInfoFormContainer">
    <span class="mt-4 mb-1 d-block">
      Ordered:<span class="fw-bold ms-1"> {{ labInfo.where }} </span>
    </span>
    <span>
      Date:<span class="fw-bold ms-1">
        {{ labInfo.whenDate | date : "shortDate" }}
      </span>
    </span>
  </ng-container>
  <ng-template #labInfoFormContainer>
    <form #labInfoForm="ngForm">
      <div class="d-flex align-items-center mb-2">
        <label for="where-address" class="form-label me-2 mb-0">Where</label>
        <input
          type="text"
          id="where-address"
          name="where-address"
          class="form-control py-1"
          [(ngModel)]="labInfo.where"
          [ngClass]="{
            'is-invalid': whereField && whereField.touched && whereField.invalid
          }"
          #whereField="ngModel"
          required
        />
      </div>
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <label for="when-datetime" class="form-label font-size-12 mb-0 me-3"
            >When</label
          >
          <input
            type="date"
            id="when-datetime"
            name="when-datetime"
            class="form-control py-1 w-75"
            [ngModel]="whenDate | date : 'yyyy-MM-dd'"
            (ngModelChange)="parseWhenDate($event)"
            [ngClass]="{
              'is-invalid':
                whenDateField && whenDateField.touched && whenDateField.invalid
            }"
            #whenDateField="ngModel"
            required
          />
        </div>
        <div>
          <button
            type="button"
            [disabled]="isSaving"
            class="btn tertiary-button"
            (click)="save()"
          >
            <i
              *ngIf="isSaving"
              class="fa-duotone fa-spinner-third fa-spin fs-5 me-1"
            ></i>
            Save
          </button>
        </div>
      </div>
    </form>
  </ng-template>
</div>

<header id="header-container">
  <div class="app-header">
    <h3 class="title">Charts</h3>
    <span #pop="bs-popover" role="button" class="user-avatar" [popover]="userProfileTemplate" placement="bottom"
      [outsideClick]="true">{{ initials }}
    </span>
    <div class="ms-2">
      <div class="fw-bold fs-5">{{ userName | titlecase }}</div>
      <span> {{ companyName }}</span>
    </div>
  </div>
</header>

<ng-template #userProfileTemplate>
  <div class="d-flex justify-content-between align-items-top mb-3 p-1">
    <span class="user-avatar">{{ initials }}</span>
    <div class="ms-2 flex-grow-1">
      <div class="fw-bold fs-5">{{ userName | titlecase }}</div>
      <span class="d-block mb-2"> {{ companyName }}</span>
      <span class="view-account-link"> View Account</span>
    </div>
    <button type="button" class="btn-close close user-profile-close-btn" aria-label="Close" (click)="pop.hide()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="footer-panel text-end">
    <span role="button" [ngClass]="loggingOut ? 'logout-loading': 'logout-btn'" (click)="logout()">
      {{loggingOut ? 'Logging out..' : 'Log Out'}}
    </span>
  </div>
</ng-template>
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Subject, takeUntil } from "rxjs";
import { ApplicationContext } from "src/app/core/models/application-context.model";
import { ApplicationContextService } from "src/app/core/services/application-context.service";
import { AuthenticationService } from "src/app/core/services/auth.service";

@Component({
  selector: "app-topbar",
  templateUrl: "./topbar.component.html",
  styleUrls: ["./topbar.component.scss"],
})
export class TopbarComponent implements OnInit {
  initials: string = "";
  userName: string;
  userEmailAddress: string;
  companyName: string;
  showCompanySwitch: boolean;
  loggingOut: boolean;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly _authService: AuthenticationService,
    private readonly _toastService: ToastrService,
    private readonly _router: Router,
    private readonly _appContextService: ApplicationContextService
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this._appContextService.context
      .pipe(takeUntil(this.destroy$))
      .subscribe((context) => {
        this.onContextChanged(context);
      });

    this._appContextService.contextChanged$
      .pipe(takeUntil(this.destroy$))
      .subscribe((context) => {
        this.onContextChanged(context);
      });
  }

  logout() {
    if (this.loggingOut) {
      return;
    }
    this.loggingOut = true;
    this._authService
      .logoutAllApps()
      .subscribe({
        next: () => {
          this._authService.logout(true);
        },
        error: (err) => {
          this._toastService.error(err || "unable to log user out.", "Error!");
        },
      })
      .add(() => (this.loggingOut = false));
  }

  gotoSelectCompanyPage() {
    this._router.navigateByUrl("/select-company");
  }

  private onContextChanged(context: ApplicationContext) {
    this.userEmailAddress = context.currentlyLoggedInUser.email;
    this.userName =
      context.currentlyLoggedInUser.firstName +
      " " +
      context.currentlyLoggedInUser.lastName;
    this.initials =
      context.currentlyLoggedInUser.firstName.charAt(0).toUpperCase() +
      context.currentlyLoggedInUser.lastName.charAt(0).toUpperCase();
    this.showCompanySwitch = context.userCompanies.length > 1;
    this.companyName = context.currentlyLoggedInUser.companyName;
  }
}

<div class="modal-header justify-content-between">
  <div class="modal-title pull-left d-flex">
    <h4 class="fw-normal m-0">UPLOAD DOCUMENT</h4>
  </div>
  <button
    type="button"
    class="btn-close close ms-1"
    aria-label="Close"
    (click)="bsModalRef.hide()"
  >
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form #patientDocForm="ngForm">
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <div class="mb-2">
          <label for="document-name">{{ docNameLabel }}:</label>
          <input
            type="text"
            class="form-control"
            name="document-name"
            [disabled]="disableName"
            [ngClass]="{
              'is-invalid':
                documentNameInputField &&
                documentNameInputField.touched &&
                documentNameInputField.invalid
            }"
            #documentNameInputField="ngModel"
            [(ngModel)]="newDoc.name"
            required
          />
        </div>
        <div *ngIf="showDocumentDate" class="mb-2">
          <label for="date-input">Date </label>
          <input
            type="date"
            class="form-control"
            name="date-input"
            [ngModel]="documentDate | date : 'yyyy-MM-dd'"
            (ngModelChange)="parseDate($event)"
            [ngClass]="{
              'is-invalid':
                documentDateField &&
                documentDateField.touched &&
                documentDateField.invalid
            }"
            #documentDateField="ngModel"
            required
          />
        </div>
        <div *ngIf="showDocType" class="mb-2">
          <label for="document-type-select">Document Type </label>
          <ng-select
            #documentTypeSelect
            [items]="documentTypes"
            bindLabel="displayName"
            bindValue="value"
            [hideSelected]="true"
            [disabled]="disableDocType"
            name="document-type-input"
            [(ngModel)]="newDoc.documentType"
            [ngClass]="{
              'is-invalid':
                documentTypeSelectField &&
                documentTypeSelectField.touched &&
                documentTypeSelectField.invalid
            }"
            #documentTypeSelectField="ngModel"
            required
            autofocus
          ></ng-select>
        </div>
      </div>
      <div class="file-upload-area">
        <file-upload [control]="control" class="p-0 text-center">
          <ng-template
            let-isFileDragDropAvailable="isFileDragDropAvailable"
            #placeholder
          >
            <div
              *ngIf="control.size === 0"
              class="d-flex flex-column justify-content-between align-items-center"
            >
              <i class="fa fa-image-alt fs-1"></i>
              <ng-container
                *ngIf="isFileDragDropAvailable; else isNotDragDropAvailable"
              >
                <span class="text-center mt-1"
                  ><b>Drag and drop</b> files or click here</span
                >
              </ng-container>
              <ng-template #isNotDragDropAvailable>
                <span class="text-center mt-1"
                  ><b>Click here</b> to choose a files</span
                >
              </ng-template>
            </div>
          </ng-template>

          <ng-template
            let-i="index"
            let-file="file"
            let-control="control"
            #item
          >
            <div class="mt-3 mx-3">
              <div class="mb-2">{{ file.size | fileSize }}</div>
              <i class="fa-duotone fa-solid fa-file d-block"></i>
              <div class="file-name">{{ file.name }}</div>
              <i
                class="fa fa-trash remove-image-icon text-danger"
                role="button"
                (click)="control.removeFile(file)"
              ></i>
            </div>
          </ng-template>
        </file-upload>
      </div>
    </div>
    <div
      *ngIf="showPhysicalExamNote"
      class="text-bold mt-4 text-center text-danger"
    >
      Note: Make sure to take physical exam before the procedure.
    </div>
  </form>
</div>
<div class="modal-footer">
  <button
    [disabled]="isSaving"
    type="button"
    class="btn secondary-button"
    (click)="bsModalRef.hide()"
  >
    Cancel
  </button>
  <button
    [disabled]="isSaving"
    type="button"
    class="btn primary-button"
    (click)="saveDocument()"
  >
    <i
      *ngIf="isSaving"
      class="fa-duotone fa-spinner-third fa-spin fs-5 me-1"
    ></i>
    Upload
  </button>
</div>

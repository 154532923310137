<div class="header-left">
  <div class="me-3">
    <div class="header-left__name">
      {{ patient?.firstName | titlecase }}
      {{ patient?.lastName | titlecase }}
    </div>
    <div class="m-0">
      <span class="header-left__age">{{ patient?.age }}</span>
      <span class="header-left__gender">{{ patient?.gender | titlecase }}</span>
      <span class="header-left__dob">{{
        patient?.dob | dateWithoutTimezone : "MM/dd/yyyy"
      }}</span>
      <span *ngIf="showPhoneNo" class="header-left__phone"
        >Phone: {{ patient?.phone | phone }}</span
      >
    </div>
  </div>
  <div class="header-left__check-in">
    <div class="m-0">Check In:</div>
    <div class="m-0">{{ checkInTime || "--" }}</div>
  </div>
  <div class="header-left__procedure">
    <p class="m-0">Procedure:</p>
    <div class="m-0">{{ procedureText || "--" }}</div>
  </div>
  <div class="header-left__physician">
    <p class="m-0">Physician:</p>
    <div class="m-0 text-dark fw-bold text-capitalize">{{ physicianName || "--" }}</div>
  </div>
  <div *ngIf="showStatus" class="header-left__physician">
    <p class="m-0">Status:</p>
    <span class="list-status-column">{{ appointmentStatus || "--" }}</span>
  </div>
</div>

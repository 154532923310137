import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoaderComponent } from "./components/loader/loader.component";
import { PagetitleComponent } from "./components/pagetitle/pagetitle.component";
import { ToastrModule } from "ngx-toastr";
import { SafeUrlPipe } from "../core/pipes/safe-url.pipe";
import { SsnDirective } from "./directives/ssn.directive";
import { UploadImageComponent } from "./components/upload-image/upload-image.component";
import { FileUploadModule } from "@iplab/ngx-file-upload";
import { PhonePipe } from "../core/pipes/phone.pipe";
import { DateWithoutTimezonePipe } from "../core/pipes/date-without-timezone.pipe";
import { ScrollSpyDirective } from "./directives/scroll-spy.directive";
import { AutofocusDirective } from "./directives/auto-focus.directive";
import { ReplaceUnderscoreWithSpacePipe } from "../core/pipes/replace-underscore-with-space.pipe";
import { YesNoPipe } from "../core/pipes/yes-no.pipe";
import { SplitCamelCasePipe } from "../core/pipes/split-camel-case.pipe";
import { SessionTimeoutComponent } from "./components/session-timeout/session-timeout";
import { DocumentAttachmentComponent } from "./components/document-attachment/document-attachment.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { UploadDocumentModalComponent } from "./components/document-attachment/upload-document-modal/upload-document-modal.component";
import { FileSizePipe } from "../core/pipes/file-size.pipe";
import { DocumentPreviewComponent } from "./components/document-attachment/document-preview/document-preview.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { PatientInfoHeaderComponent } from "./components/patient-info-header/patient-info-header.component";
import { ProgressbarModule } from "ngx-bootstrap/progressbar";
import { ChecklistQuestionsComponent } from "./components/checklist-questions/checklist-questions.component";
import { UploadDocumentComponent } from './components/upload-document/upload-document.component';
import { LabOrderedViewComponent } from './components/upload-document/lab-ordered-view/lab-ordered-view.component';
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
@NgModule({
  declarations: [
    LoaderComponent,
    PagetitleComponent,
    UploadImageComponent,
    SafeUrlPipe,
    SsnDirective,
    PhonePipe,
    DateWithoutTimezonePipe,
    ScrollSpyDirective,
    YesNoPipe,
    AutofocusDirective,
    ReplaceUnderscoreWithSpacePipe,
    SplitCamelCasePipe,
    SessionTimeoutComponent,
    DocumentAttachmentComponent,
    UploadDocumentModalComponent,
    DocumentPreviewComponent,
    FileSizePipe,
    PatientInfoHeaderComponent,
    ChecklistQuestionsComponent,
    UploadDocumentComponent,
    LabOrderedViewComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FileUploadModule,
    ToastrModule.forRoot({ timeOut: 3000 }),
    NgSelectModule,
    ProgressbarModule,
    BsDropdownModule.forRoot(),
  ],
  exports: [
    LoaderComponent,
    PagetitleComponent,
    SafeUrlPipe,
    SsnDirective,
    UploadImageComponent,
    PhonePipe,
    YesNoPipe,
    DateWithoutTimezonePipe,
    ScrollSpyDirective,
    AutofocusDirective,
    ReplaceUnderscoreWithSpacePipe,
    SplitCamelCasePipe,
    SessionTimeoutComponent,
    DocumentAttachmentComponent,
    FileSizePipe,
    PatientInfoHeaderComponent,
    ChecklistQuestionsComponent,
    UploadDocumentComponent
  ],
})
export class SharedModule {}

<div class="modal-header justify-content-between">
  <div class="modal-title pull-left d-flex align-items-center">
    <h4 class="fw-normal m-0 text-uppercase">View Document</h4>
  </div>
  <button
    type="button"
    class="btn-close close ms-1"
    aria-label="Close"
    (click)="bsModalRef.hide()"
  >
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>
<div class="modal-body modal-fixed-height">
  <div *ngIf="pdfSrc">
    <iframe
      style="height: calc(100vh - 12rem)"
      [src]="pdfSrc | safeUrl"
      width="100%"
      frameborder="0"
    ></iframe>
  </div>
</div>

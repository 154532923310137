import { Injectable } from "@angular/core";
import { DataService } from "./data.service";
import { environment } from "src/environments/environment";
import { OldLabInfo } from "../models/old-labs.model";
import { catchError, Observable, of, tap, throwError } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { ApplicationCacheService } from "./application-cache.service";
import { ApiStatus, ReferralApis } from "./utils";
import { cloneDeep } from "lodash";

@Injectable({
  providedIn: "root",
})
export class OldLabsService {
  constructor(
    private readonly _dataService: DataService,
    private readonly _toastService: ToastrService,
    private readonly _applicationCacheService: ApplicationCacheService
  ) {}

  getAppointmentLabs(appointmentId: string): Observable<OldLabInfo[]> {
    if (this.isEncounterLabsAlreadyLoaded) {
      return of(this.labsCache);
    }
    const url = this.apiBaseUrl + `api/v1/labs/encounter/${appointmentId}`;
    return this._dataService.get(url).pipe(
      tap((labs: OldLabInfo[]) => {
        this._applicationCacheService.setLabInfoCache(labs);
        this._applicationCacheService.setApiStatus(
          ApiStatus.Success,
          ReferralApis.Labs
        );
      }),
      catchError((error) => {
        this._toastService.error(
          error || "Unable to get Patient labs due to unknown reason.",
          "Error!"
        ),
          this._applicationCacheService.setApiStatus(
            ApiStatus.Failed,
            ReferralApis.Labs
          );
        return throwError(() => error);
      })
    );
  }

  saveLabResults(payload: OldLabInfo) {
    const url = this.apiBaseUrl + `api/v1/labs`;
    return this._dataService.post(url, payload);
  }

  get isEncounterLabsAlreadyLoaded(): boolean {
    return (
      this._applicationCacheService.getApiStatus(ReferralApis.Labs) ===
      ApiStatus.Success
    );
  }

  get labsCache(): OldLabInfo[] {
    return cloneDeep(this._applicationCacheService.applicationCache.labs);
  }

  get apiBaseUrl(): string {
    return environment.apiBaseUrl;
  }
}
